import React from "react"

import { PageLayout } from "@components/layouts"
import SEO from "@components/seo"
import TextBlock from "@components/textBlock"
import SingleColumnBlock from "@components/singleColumnBlock"
import TripleColumnBlock from "@components/tripleColumnBlock"
import PageWrapper from "@components/pageWrapper"
import ReactPlayer from 'react-player'

import {
  QuickLinkButton,
} from "@components/buttons"

import {
  LearnMoreButton,
} from "@components/buttons"

import {
  FormWrapper,
  RequestMoreInfo,
} from "@components/Forms"

const IndexPage = () => {
  return (
    <PageLayout>
      <SEO
        title="Business Podcast: Websuasion Conversation Season One"
        ogDescription="A business development podcast with technology tips and conversation with executives, entrepreneurs, and business owners."
        image="/images/podcast/season-one/websuasion-conversation.jpg"
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h1>Business Podcast:<br />
						<span className="websuasion">websuasion conversation</span><br />
						season one</h1>
						<h4>A business development podcast with technology tips and conversation with executives, entrepreneurs, and business owners.</h4>
            <p>
							On our business development podcast, J. Ryan Williams sits down with executives, business owners, and entrepreneurs. They share their experiences in business development and entrepreneurship.
						</p>
						<p>
							<span className="websuasion">websuasion conversasion</span> is available on your favorite podcast platform including: <a href="https://itunes.apple.com/us/podcast/websuasion-conversation/id1454464479">Apple Podcasts</a> and <a href="https://open.spotify.com/show/521lym6rKJPoWQZbeUROuG">Spotify</a>.
            </p>
						<h2>Subscribe To Our Business Podcast</h2>
						<p>
							We are excited to be available on the most popular podcasting platforms. Just click on the links below and the show should pop up in your favorite podcasting app. And please leave us a review! That's the best way to help us spread the word. Thank you!
						</p>
						<p>
							<a href="https://itunes.apple.com/us/podcast/websuasion-conversation/id1454464479" target="_blank">
								<img src="/images/podcast/apple-podcasts-150x150.png" className="icon" alt="Websuasion Conversasion on Apple Podcasts" />
							</a>
							<a href="https://open.spotify.com/show/521lym6rKJPoWQZbeUROuG" target="_blank">
								<img src="/images/podcast/spotify-150x150.png" className="icon" alt="Websuasion Conversasion on Spotify" />
							</a>
						</p>
						<p>
							Podcast RSS feed:<br /><br />

							<span className="red">https://websuasion.com/podcast.xml</span>
						</p>
					</div>
        }
        textRight={
          <div className="centered">
            <img src="/images/podcast/season-one/websuasion-conversation.jpg" className="rounded-square" alt="Business Podcast - Websuasion Conversation Season One" />
          </div>
        }
      />
      <SingleColumnBlock
        text={
          <div className="general-block">
            <a href="/podcast/season-one/corporate-relocations-with-jill-heineck/"><img src="/images/podcast/season-one/jill-heineck.jpg" className="rounded-square" alt="Corporate Relocations with Jill Heineck" width="300" /></a>
            <h3><a href="/podcast/season-one/corporate-relocations-with-jill-heineck/">Corporate Relocations with Jill Heineck</a></h3>
            <p>
							Jill is a Realtor, Writer, and Speaker. She is the president of Heineck & Company, a Keller Williams firm, specializing in Corporate Relocation. Jill is also the host of the Customer Experience Radio Podcast inspired by the companies who make an impact by putting their customer’s experience first.
            </p>
          </div>
        }
      />
      <TripleColumnBlock
        centered
        textPadded
        textLeft={
          <div className="general-block">
            <a href="/podcast/season-one/investing-in-your-team-with-kristen-hendricks/"><img src="/images/podcast/season-one/kristen-hendricks.jpg" className="rounded-square" alt="Investing In Your Team with Kristen Hendricks" width="300" /></a>
            <h3><a href="/podcast/season-one/investing-in-your-team-with-kristen-hendricks/">Investing In Your Team with Kristen Hendricks</a></h3>
            <p>
							Kristen holds a counseling degree from SUNY Alfred and brings over a decade of experience in client relations to Marshall Jones, an accounting firm based in Atlanta. Her efforts in reimagining the business development of the firm resulted in her promotion to full partner status as of January 1, 2019. She combines her experience in health care and counseling with business acumen to build productive and happy teams.
            </p>
          </div>
        }
        textCenter={
          <div className="general-block">
            <a href="/podcast/season-one/sionic-mobile-ulink-technology-with-bob-burroughs/"><img src="/images/podcast/season-one/bob-burroughs.jpg" className="rounded-square" alt="Sionic Mobile Ulink Technology with Bob Burroughs" width="300" /></a>
            <h3><a href="/podcast/season-one/sionic-mobile-ulink-technology-with-bob-burroughs/">Sionic Mobile Ulink Technology with Bob Burroughs</a></h3>
            <p>
							Bob led Bellsouth Mobility (now AT&T) in the launch of several consumer products including voice connect and one-number service. He headed product planning and marketing for IntelliOne and launched the company’s ioVector application for iPhone and Blackberry – a traffic navigation and trip planning app marketed through Rogers Communications throughout Canada. He has been head of EVP Products for Sionic Mobile since 2010.
            </p>
          </div>
        }
        textRight={
          <div className="general-block">
            <a href="/podcast/season-one/the-long-term-care-insurance-process-with-amber-dawson/"><img src="/images/podcast/season-one/amber-dawson.jpg" className="rounded-square" alt="The Long Term Care Insurance Process with Amber Dawson" width="300" /></a>
            <h3><a href="/podcast/season-one/the-long-term-care-insurance-process-with-amber-dawson/">The Long Term Care Insurance Process with Amber Dawson</a></h3>
            <p>
							Amber has worked as a travel agent, executive administrator, and run an event coordination business. She is now a para-planner at the Long Term Care Planning Group, teaching clients the benefits of Long Term Care Insurance Coverage and matching them with the best carrier and solution.
            </p>
          </div>
        }
      />
      <TripleColumnBlock
        centered
        textPadded
        textLeft={
          <div className="general-block">
            <a href="/podcast/season-one/jessica-walker-healthy-habits/"><img src="/images/podcast/season-one/jessica-walker.jpg" className="rounded-square" alt="Establishing Healthy Habits with Jessica Walker" width="300" /></a>
            <h3><a href="/podcast/season-one/jessica-walker-healthy-habits/">Establishing Healthy Habits with Jessica Walker</a></h3>
            <p>
							Jessica Walker is a certified Integrative Nutrition Health Coach, Precision Nutrition Level 1 Coach, and Dragon Boat Paddling Trainer based in Peachtree City, GA. She guides her clients into the best shape of their lives and establishes behavior so they stay that way.
            </p>
          </div>
        }
        textCenter={
          <div className="general-block">
            <a href="/podcast/season-one/commercial-lending-with-srdjan-gavrilovic/"><img src="/images/podcast/season-one/srdjan-gavrilovic.jpg" className="rounded-square" alt="Commercial Lending with Srdjan Gavrilovic" width="300" /></a>
            <h3><a href="/podcast/season-one/commercial-lending-with-srdjan-gavrilovic/">Commercial Lending with Srdjan Gavrilovic</a></h3>
            <p>
							Srdjan Gavrilovic is a Senior Business Banker at First Citizens Bank in Atlanta. He finds creative ways to provide his clients more capital, better structured capital, and improve working capital management. He focuses on Healthcare, Real Estate and Equipment financing, Business Acquisition and partner buy-outs.
            </p>
          </div>
        }
        textRight={
          <div className="general-block">
            <a href="/podcast/season-one/property-management-venis-sims/"><img src="/images/podcast/season-one/venis-sims.jpg" className="rounded-square" alt="Property Management with Venis Sims" width="300" /></a>
            <h3><a href="/podcast/season-one/property-management-venis-sims/">Property Management with Venis Sims</a></h3>
            <p>
							Venis came from the mortgage industry before becoming a real estate broker with a focus on property management. She focuses on finding and managing investment properties handling the day to day frustrations of maintenance and tenant interaction for her clients.
            </p>
          </div>
        }
      />
      <TripleColumnBlock
        centered
        textPadded
        textLeft={
          <div className="general-block">
            <a href="/podcast/season-one/pest-control-with-greg-verjan/"><img src="/images/podcast/season-one/greg-verjan.jpg" className="rounded-square" alt="Pest Control with Greg Verjan" width="300" /></a>
            <h3><a href="/podcast/season-one/pest-control-with-greg-verjan/">Pest Control with Greg Verjan</a></h3>
            <p>
							Over 25 years of experience, coupled with customer service unmatched in the industry, Greg Verjan, owner of Turin Pest Control, is committed to excellent service – every single time. Family owned and operated, Turin Pest Control is the only choice for pest control services in Fayette and Coweta.
            </p>
          </div>
        }
        textCenter={
          <div className="general-block">
            <a href="/podcast/season-one/social-security-benefit-planning-with-faye-sykes/"><img src="/images/podcast/season-one/faye-sykes-scarlet-oakfs.jpg" className="rounded-square" alt="Social Security Benefit Planning with Faye Sykes" width="300" /></a>
            <h3><a href="/podcast/season-one/social-security-benefit-planning-with-faye-sykes/">Social Security Benefit Planning with Faye Sykes</a></h3>
            <p>
							Faye Sykes is one of the nation’s experts on Social Security benefits and retirement income planning, as well as a financial advisor for individuals, couples, and families. She enthusiastically serves a wide variety of business and personal clients through her two firms, Scarlet Oak Financial Services, and Social Security Benefit Planners as CEO, with a focus on delivering comprehensive financial planning, wealth management, and Social Security benefits planning services.
            </p>
          </div>
        }
        textRight={
          <div className="general-block">
            <a href="/podcast/season-one/service-based-business-with-mike-dingler/"><img src="/images/podcast/season-one/mike-dingler.jpg" className="rounded-square" alt="Service Based Business with Mike Dingler" width="300" /></a>
            <h3><a href="/podcast/season-one/service-based-business-with-mike-dingler/">Service Based Business with Mike Dingler</a></h3>
            <p>
							Mike Dingler is a nationally registered and formally a full-time Firefighter/Paramedic. Now a volunteer as a Firefighter/Paramedic, Mike runs Firehouse Pressure Washing which is staffed entirely by Firefighters. Mike is an author of the book “Small Service Based Business Basics: How to run your business and not let your business run you”. He is launching the FireTrack app in early 2020 to connect the public to Firefighters providing various home services on their days off.
            </p>
          </div>
        }
      />
      <TripleColumnBlock
        centered
        textPadded
        textLeft={
          <div className="general-block">
            <a href="/podcast/season-one/wealth-management-with-kevin-salvadori/"><img src="/images/podcast/season-one/kevin-salvadori.jpg" className="rounded-square" alt="Wealth Management with Kevin Salvadori" width="300" /></a>
            <h3><a href="/podcast/season-one/wealth-management-with-kevin-salvadori/">Wealth Management with Kevin Salvadori</a></h3>
            <p>
							Kevin was a professional basketball player with the Sacramento Kings who transitioned into pharmaceutical sales for many years before entering the world of finance. He is now an Independent Wealth Manager who coaches clients through their financial life. His personal history allows him to be an empathetic and unbiased mentor to his clients, while still pushing them to be prepared and to not sit on the sidelines of their finances.
            </p>
          </div>
        }
        textCenter={
          <div className="general-block">
            <a href="/podcast/season-one/consolidation-accounting-with-andrea-lockhart/"><img src="/images/podcast/season-one/andrea-lockhart.jpg" className="rounded-square" alt="Consolidation Accounting with Andrea Lockhart" width="300" /></a>
            <h3><a href="/podcast/season-one/consolidation-accounting-with-andrea-lockhart/">Consolidation Accounting with Andrea Lockhart</a></h3>
            <p>
							Andrea is an accounting and audit professional with over 24 years of public and private industry experience. She has extensive knowledge of consolidation accounting and currency conversion with multi-national publicly traded companies using Hyperion Financial Management (HFM) and Hyperion Enterprise, and foreign currency translation. She is also an avid marathon runner and triathlete.
            </p>
          </div>
        }
        textRight={
          <div className="general-block">
            <a href="/podcast/season-one/vistage-peer-advisory-board-marc-borrelli/"><img src="/images/podcast/season-one/marc-borrelli.jpg" className="rounded-square" alt="Peer Advisory Board with Marc Borrelli" width="300" /></a>
            <h3><a href="/podcast/season-one/vistage-peer-advisory-board-marc-borrelli/">Peer Advisory Board with Marc Borrelli</a></h3>
            <p>
							With 30 years of strategy and investment banking experience, Marc Borrelli is expertly positioned to offer a range of unique advisory services. Across Europe, Africa and the United States, he’s closed more than 100 transactions worth over $3 billion. He is also currently a Vistage chair.
            </p>
          </div>
        }
      />
      <TripleColumnBlock
        centered
        textPadded
        textLeft={
          <div className="general-block">
            <a href="/podcast/season-one/merging-cpa-practices-with-brandon-verner/"><img src="/images/podcast/season-one/brandon-verner.jpg" className="rounded-square" alt="Merging CPA Practices with Brandon Verner" width="300" /></a>
            <h3><a href="/podcast/season-one/merging-cpa-practices-with-brandon-verner/">Merging CPA Practices with Brandon Verner</a></h3>
            <p>
							Brandon S. Verner is a CPA and an Atlanta native who specializes in accounting systems and consulting as well as corporate and personal income tax. Brandon is an active member of the Georgia Society of Certified Public Accountants and the American Institute of Certified Public Accountants. He is a past President of the North Perimeter Chapter of the Georgia Society of CPAs and has served on the Board of Directors of the State Society.
            </p>
          </div>
        }
        textCenter={
          <div className="general-block">
            <a href="/podcast/season-one/senior-home-care-with-alan-waters/"><img src="/images/podcast/season-one/alan-waters.jpg" className="rounded-square" alt="Senior Home Care with Alan Waters" width="300" /></a>
            <h3><a href="/podcast/season-one/senior-home-care-with-alan-waters/">Senior Home Care with Alan Waters</a></h3>
            <p>
							Alan is the owner of an Amada franchise in north Atlanta. Amada Senior Care is one of the fastest-growing home care provider companies in the United States, focused on serving aging and disabled adults who elect to remain in the privacy and comfort of their own homes.
            </p>
          </div>
        }
        textRight={
          <div className="general-block">
            <a href="/podcast/season-one/building-a-thriving-business-with-nermin-jasani/"><img src="/images/podcast/season-one/nermin-jasani.jpg" className="rounded-square" alt="Building a Thriving Business with Nermin Jasani" width="300" /></a>
            <h3><a href="/podcast/season-one/building-a-thriving-business-with-nermin-jasani/">Building a Thriving Business with Nermin Jasani</a></h3>
            <p>
							Nermin Jasani is a former Wall Street attorney and former tech startup entrepreneur who now runs Highland Oak Group. She has worked with hundreds of business owners providing her proven methodology to increase revenue that is based on calculated, intentional, and results-driven metrics.
            </p>
          </div>
        }
      />
      <TripleColumnBlock
        centered
        textPadded
        textLeft={
          <div className="general-block">
            <a href="/podcast/season-one/strategic-planning-with-liz-parker/"><img src="/images/podcast/season-one/liz-parker.jpg" className="rounded-square" alt="Strategic Planning with Liz Parker" width="300" /></a>
            <h3><a href="/podcast/season-one/strategic-planning-with-liz-parker/">Strategic Planning with Liz Parker</a></h3>
            <p>
							Liz Parker is president of LT Consultants, Inc. She uses the 3 P’s: Purpose, Passion and Practicality to inspire, coach, challenge and facilitate leadership teams. She uncovers their true and effective vision with strong values and a focused strategic plan. Liz is also a Vistage Chair. Vistage is a peer advisory group designed especially for executive business leaders.
            </p>
          </div>
        }
        textCenter={
          <div className="general-block">
            <a href="/podcast/season-one/ga-retraining-tax-credits-jonathan-warner/"><img src="/images/podcast/season-one/jonathan-warner.jpg" className="rounded-square" alt="Georgia Retraining Tax Credits with Jonathan Warner" width="300" /></a>
            <h3><a href="/podcast/season-one/ga-retraining-tax-credits-jonathan-warner/">Georgia Retraining Tax Credits with Jonathan Warner</a></h3>
            <p>
							Jonathan Warner is a seasoned training, organization development, and performance improvement professional with over 20 years of experience assisting clients to develop their workforce for compliance, quality, and maximum performance. His company, Workforce Training Partners, maximize tax credits for Georgia-based businesses by uncovering hidden opportunities that other accounting firms might miss.
            </p>
          </div>
        }
        textRight={
          <div className="general-block">
            <a href="/podcast/season-one/video-in-real-estate-gerald-wessels/"><img src="/images/podcast/season-one/gerald-wessels.jpg" className="rounded-square" alt="Video in Real Estate with Gerald Wessels" width="300" /></a>
            <h3><a href="/podcast/season-one/video-in-real-estate-gerald-wessels/">Video in Real Estate with Gerald Wessels</a></h3>
            <p>
							Gerald Wessels grew up farming and ranching in northwest Oklahoma. He has a Masters of Divinity and a Doctorate of Ministry and pastored churches in Texas, Oklahoma, and Colorado. He worked at luxury car dealerships leading in sales from year-to-year by treating customers “just as one would treat their mother.” He now brings that same commitment to the real estate industry in finding his customers the perfect home.
            </p>
          </div>
        }
      />
      <TripleColumnBlock
        centered
        textPadded
        textLeft={
          <div className="general-block">
            <a href="/podcast/season-one/healthcare-it-services-with-sheryl-cherico/"><img src="/images/podcast/season-one/sheryl-cherico.jpg" className="rounded-square" alt="Healthcare IT Services with Sheryl Cherico" width="300" /></a>
            <h3><a href="/podcast/season-one/healthcare-it-services-with-sheryl-cherico/">Healthcare IT Services with Sheryl Cherico</a></h3>
            <p>
							Sheryl Cherico started Tier3MD in 2005 and it has grown to one of the nations leading HIPAA compliant IT support groups for medical practices. They provide comprehensive Security Risk Assessments for a wide variety of medical practices. She is also president of the Georgia Breast Cancer Coalition, which provides assistance for breast cancer patients in need.
            </p>
          </div>
        }
        textCenter={
          <div className="general-block">
            <a href="/podcast/season-one/improving-your-cash-flow-with-david-wiener/"><img src="/images/podcast/season-one/david-wiener.jpg" className="rounded-square" alt="Improving Your Cash Flow with David Wiener" width="300" /></a>
            <h3><a href="/podcast/season-one/improving-your-cash-flow-with-david-wiener/">Improving Your Cash Flow with David Wiener</a></h3>
            <p>
							David Wiener has earned the nickname “Mr. Cashflow”. He finds ways to increase his clients’ revenue, lower taxes, and cut expenses through cost segregation studies, profit recovery, and cost reduction.
            </p>
          </div>
        }
        textRight={
          <div className="general-block">
            <a href="/podcast/season-one/building-social-media-communities-with-melissa-howell/"><img src="/images/podcast/season-one/melissa-howell.jpg" className="rounded-square" alt="Building Social Media Communities with Melissa Howell" width="300" /></a>
            <h3><a href="/podcast/season-one/building-social-media-communities-with-melissa-howell/">Building Social Media Communities with Melissa Howell</a></h3>
            <p>
							Melissa Howell offers extensive experience as a Strategic Marketing Consultant and Social Media Manager. Focusing on Instagram, Twitter, Facebook, Pinterest, Google+ and Youtube, she creates personalized content, develops branding, and writes newsletters and blogs. She is focused on helping business owners find their voice in this digital world!
            </p>
          </div>
        }
      />
      <TripleColumnBlock
        centered
        textPadded
        textLeft={
          <div className="general-block">
            <a href="/podcast/season-one/property-casualty-insurance-with-christina-rudes/"><img src="/images/podcast/season-one/christina-rudes.jpg" className="rounded-square" alt="Property & Casualty Insurance with Christina Rudes" width="300" /></a>
            <h3><a href="/podcast/season-one/property-casualty-insurance-with-christina-rudes/">Property & Casualty Insurance with Christina Rudes</a></h3>
            <p>
							Christina Rudes is a licensed Property & Casualty Insurance Agent running The Fitzpatrick Agency with her husband Gary. She is involved in the local community serving on the Marietta Police Athletic League Shamrock Shuffle, where she is Committee Chair.
            </p>
          </div>
        }
        textCenter={
          <div className="general-block">
            <a href="/podcast/season-one/accounting-and-tax-law-with-ben-loggins/"><img src="/images/podcast/season-one/ben-loggins.jpg" className="rounded-square" alt="Accounting and Tax Law with Ben Loggins" width="300" /></a>
            <h3><a href="/podcast/season-one/accounting-and-tax-law-with-ben-loggins/">Accounting and Tax Law with Ben Loggins</a></h3>
            <p>
							Ben R. Loggins, CPA worked for the Internal Revenue Service as an agent and instructor for over six years. In 1980 he came to Jonesboro to form his own accounting firm which has grown to 15 employees. He served as a member of the Governing Council and Finance Committee of the US Figure Skating Association, as well as, Treasurer for the Clayton County Olympic Committee.
            </p>
          </div>
        }
        textRight={
          <div className="general-block">
            <a href="/podcast/season-one/mortgages-financial-tool-john-fortener/"><img src="/images/podcast/season-one/john-fortner.jpg" className="rounded-square" alt="Mortgages as a Financial Tool with John Fortener" width="300" /></a>
            <h3><a href="/podcast/season-one/mortgages-financial-tool-john-fortener/">Mortgages as a Financial Tool with John Fortener</a></h3>
            <p>
							John Fortener of Success Mortgage Partners has over 20 years of experience in the Mortgage Industry. He utilizes mortgages as a financial tool that complements long term financial & retirement goals.
            </p>
          </div>
        }
      />
      <TripleColumnBlock
        centered
        textPadded
        textLeft={
          <div className="general-block">
            <a href="/podcast/season-one/social-media-marketing-with-emma-loggins/"><img src="/images/podcast/season-one/emma-loggins.jpg" className="rounded-square" alt="Social Media Marketing with Emma Loggins" width="300" /></a>
            <h3><a href="/podcast/season-one/social-media-marketing-with-emma-loggins/">Social Media Marketing with Emma Loggins</a></h3>
            <p>
							Emma Loggins is an entrepreneur and self-proclaimed “geek girl”. She runs the Fanbolt site which covers the entertainment industry, as well as, her own marketing firm Excite Creative Studios.
            </p>
          </div>
        }
        textCenter={
          <div className="general-block">
            <a href="/podcast/season-one/long-term-care-with-corey-rieck/"><img src="/images/podcast/season-one/corey-reick.jpg" className="rounded-square" alt="Long Term Care with Corey Rieck" width="300" /></a>
            <h3><a href="/podcast/season-one/long-term-care-with-corey-rieck/">Long Term Care with Corey Rieck</a></h3>
            <p>
							Corey Rieck is the President and Founder of The Long Term Care Planning Group, a firm that specializes in delivering Long Term Care education and coverage to companies, high net worth individuals and large organizations.
            </p>
          </div>
        }
        textRight={
          <div className="general-block">
            <a href="/podcast/season-one/buying-a-travel-franchise-with-cayce-callaway/"><img src="/images/podcast/season-one/cayce-callaway.jpg" className="rounded-square" alt="Buying a Travel Franchise with Cayce Callaway" width="300" /></a>
            <h3><a href="/podcast/season-one/buying-a-travel-franchise-with-cayce-callaway/">Buying a Travel Franchise with Cayce Callaway</a></h3>
            <p>
							Cayce Callaway comes from a background as an actress and producer in the film industry and has had a lengthy career as an event photographer. She made the transition to travel planning starting a successful Cruise Planners franchise in midtown Atlanta.
            </p>
          </div>
        }
      />
      <TripleColumnBlock
        centered
        textPadded
        textLeft={
          <div className="general-block">
            <a href="/podcast/season-one/life-by-design-darrah-brustein/"><img src="/images/podcast/season-one/darrah-brustein.jpg" className="rounded-square" alt="Life By Design with Darrah Brustein" width="300" /></a>
            <h3><a href="/podcast/season-one/life-by-design-darrah-brustein/">Life By Design with Darrah Brustein</a></h3>
            <p>
							Equal parts teacher, mega connecter, and founder, Darrah Brustein stands for entrepreneurial empowerment. In the fall of 2018, Darrah launched “Life by Design, Not by Default” sharing stories with visionaries – like Deepak Chopra, Adam Grant, and Jen Sincero – in a 45 speaker online summit reaching over 7,000 people.
            </p>
          </div>
        }
        textCenter={
          <div className="general-block">
            <a href="/podcast/season-one/branding-with-kriston-sellier-of-id8/"><img src="/images/podcast/season-one/kriston-sellier-id8.jpg" className="rounded-square" alt="Branding with Kriston Sellier Of ID8" width="300" /></a>
            <h3><a href="/podcast/season-one/branding-with-kriston-sellier-of-id8/">Branding with Kriston Sellier Of ID8</a></h3>
            <p>
							In her role as CEO of id8, it is Kriston Sellier’s responsibility to cultivate the company culture she has envisioned since she opened the agency’s doors in 2000—one that nurtures new and innovative thinking, a relaxed approach to communicating with clients, and an understanding of the critical role design thinking plays in every business strategy.
            </p>
          </div>
        }
        textRight={
          <div className="general-block">
            <a href="/podcast/season-one/todd-anduze-small-business-development-center/"><img src="/images/podcast/season-one/todd-anduze.jpg" className="rounded-square" alt="Todd Anduze of the Small Business Development Center" width="300" /></a>
            <h3><a href="/podcast/season-one/todd-anduze-small-business-development-center/">Todd Anduze of the Small Business Development Center</a></h3>
            <p>
							Todd Anduze is the Area Director of the Small Business Development Center at the University of Georiga. The SBDC’s goal is to enhance the economic well-being of Georgians by providing a wide range of educational services for small business owners and aspiring entrepreneurs. Todd utilizes his experiences in the military, retail/manufacturing environments, and accounting services to promote the UGA SBDC.
            </p>
          </div>
        }
      />
      <TripleColumnBlock
        centered
        textPadded
        textLeft={
          <div className="general-block">
            <a href="/podcast/season-one/business-planning-with-rebecca-brizi/"><img src="/images/podcast/season-one/rebecca-brizi.jpg" className="rounded-square" alt="Business Planning with Rebecca Brizi" width="300" /></a>
            <h3><a href="/podcast/season-one/business-planning-with-rebecca-brizi/">Business Planning with Rebecca Brizi</a></h3>
            <p>
							Rebecca Brizi is a Strategic Development and Business Operations Consultant. She comes from over 10 years of experience taking Linex Systems from their initial startup phase to a new market and subsidiary company in the United States. Now as an independent consultant, she’s adept at bringing beginning-to-end enterprise operational management to her clients.
            </p>
          </div>
        }
        textCenter={
          <div className="general-block">
            <a href="/podcast/season-one/home-automation-entrepreneurship-dante-stephens/"><img src="/images/podcast/season-one/dante-stephens.jpg" className="rounded-square" alt="Home Automation Entrepreneurship with Dante Stephens" width="300" /></a>
            <h3><a href="/podcast/season-one/home-automation-entrepreneurship-dante-stephens/">Home Automation Entrepreneurship with Dante Stephens</a></h3>
            <p>
							Dante transforms everyday houses into modern day homes by providing state-of-the-art and easy to use home automation technology. He has recently merged with a partner to form NuWave Automation.
            </p>
          </div>
        }
        textRight={
          <div className="general-block">
            <a href="/podcast/season-one/legal-marketing-with-heather-riggs/"><img src="/images/podcast/season-one/heather-riggs.jpg" className="rounded-square" alt="Legal Marketing with Heather Riggs" width="300" /></a>
            <h3><a href="/podcast/season-one/legal-marketing-with-heather-riggs/">Legal Marketing with Heather Riggs</a></h3>
            <p>
							Attorney Heather Riggs has always had an enthusiasm for innovation in the legal industry. She has dedicated her career to helping fellow members of the profession plan, build, and drive their online marketing presence.
            </p>
          </div>
        }
      />
      <TripleColumnBlock
        centered
        textPadded
        textLeft={
          <div className="general-block">
            <a href="/podcast/season-one/serial-tech-entrepreneur-rob-riggs/"><img src="/images/podcast/season-one/rob-riggs.jpg" className="rounded-square" alt="Technology Entrepreneurship with Rob Riggs" width="300" /></a>
            <h3><a href="/podcast/season-one/serial-tech-entrepreneur-rob-riggs/">Technology Entrepreneurship with Rob Riggs</a></h3>
            <p>
							Rob is a serial tech entrepreneur and speaker. His company Your Design Online works with Fortune 500 firms as well as small businesses. Additionally, he is a partner in National Benefit Programs, Quantum Lead Solutions, and AcesoScript.
            </p>
          </div>
        }
        textCenter={
          <div className="general-block">
            <a href="/podcast/season-one/alternative-business-capital/"><img src="/images/podcast/season-one/ray-johnson-barrington-capital.jpg" className="rounded-square" alt="Alternative Capital with Ray Johnson" width="300" /></a>
            <h3><a href="/podcast/season-one/alternative-business-capital/">Alternative Capital with Ray Johnson</a></h3>
            <p>
							Through his company Barrington Commercial Capital, Ray Johnson provides business loans to companies of all sizes and in all stages of growth. In this interview, Ray Johnson talks about the different alternative business capital solutions his company provides businesses in all stages of growth.
            </p>
          </div>
        }
        textRight={
          <div className="general-block">
            <a href="/podcast/season-one/personal-style-business-fashion/"><img src="/images/podcast/season-one/diane-b-morris.jpg" className="rounded-square" alt=">Personal Style with Diane B. Morris" width="300" /></a>
            <h3><a href="/podcast/season-one/personal-style-business-fashion/">Personal Style with Diane B. Morris</a></h3>
            <p>
              Diane is a personal stylist, personal shopper, author, and dietitian who is committed to creating her clients’ authentic image statements. After 27 years working in a health care corporate environment, she started BrandtasticYou. Her book, Build Your Confidence through Wardrobe, Color & Style is available on Amazon.com.
            </p>
          </div>
        }
      />
      <TripleColumnBlock
        centered
        textPadded
        textLeft={
          <div className="general-block">
            <a href="/podcast/season-one/business-law-mediation-frank-g-goldman/"><img src="/images/podcast/season-one/frank-g-goldman-pc.jpg" className="rounded-square" alt="Business Law and Mediation with Frank G. Goldman, P.C." width="300" /></a>
            <h3><a href="/podcast/season-one/business-law-mediation-frank-g-goldman/">Business Law and Mediation with Frank G. Goldman, P.C.</a></h3>
            <p>
							Frank G. Goldman provides strategic legal guidance to businesses, their owners, executives, officers, and managers in a wide variety of legal disciplines. In this episode we discuss contracts, operating agreements, the legal needs of Physician Groups, hiring issues, and the difference between Arbitration and Mediation.
            </p>
          </div>
        }
        textCenter={
          <div className="general-block">
            <a href="/podcast/season-one/business-leadership-valuation-jeff-bartholomew/"><img src="/images/podcast/season-one/jeff-bartholomew.jpg" className="rounded-square" alt="Business Leadership and Valuation with Jeff Bartholomew" width="300" /></a>
            <h3><a href="/podcast/season-one/business-leadership-valuation-jeff-bartholomew/">Business Leadership and Valuation with Jeff Bartholomew</a></h3>
            <p>
              Jeff Bartholomew of Everest Business Coaching discusses taking risks, self-doubt, teaching, connections, communication, accountability, and exit strategies.
            </p>
          </div>
        }
        textRight={
          <div className="general-block">
            <a href="/podcast/season-one/maria-hall-client-attraction/"><img src="/images/podcast/season-one/maria-hall.jpg" className="rounded-square" alt="Client Attraction with Maria C. Hall" width="300" /></a>
            <h3><a href="/podcast/season-one/maria-hall-client-attraction/">Client Attraction with Maria C. Hall</a></h3>
            <p>
              In the inaugural episode of our Websuasion Conversation podcast, Maria C. Hall of Ignite Business Coaching talks about client attraction, networking and finding your niche.
            </p>
          </div>
        }
      />
			<PageWrapper color="charcole">
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div className="double-frame-block">
	            <h3><a href="/podcast/">the marketing mingle with websuasion</a></h3>
	            <p>We revived the podcast in 2024 under a new name with a new focus on marketing topics. Join us for <strong>the marketing mingle with websuasion</strong>.<br /><br /><a href="/podcast/">Listen</a></p>
	          </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="white">
				<TextBlock
	        textPadded
	        textLeft={
	          <div>
				      <div className='player-wrapper'>
				        <ReactPlayer
				          className='react-player'
				          url='https://www.youtube.com/watch?v=-YTR647HTBM'
				          width='100%'
				          height='100%'
				        />
							</div>
	          </div>
	        }
	        textRight={
	          <div>
	            <h4>Let's Talk!</h4>
	            <p>Websuasion combines data analytics, cutting-edge tech, and expert teams. "Data. Tech. Team." Those are the words we live by at websuasion. Our services foster growth and scalability for your business - all while maintaining your brand. We are an indispensable ally for you as you navigate online marketing. Let's talk. We'd love to know more about what you do and how we can solve your pain points.</p>
	          </div>
	        }
	      />
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
	            <RequestMoreInfo />
	          </div>
	        }
	      />
			</PageWrapper>
    </PageLayout>
  )
}

export default IndexPage
